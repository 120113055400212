body, html {
    margin: 0;
    font-family: Roboto;
}

.page-container {
    display: flex;
    height: 100vh;
}

.login-block-wrapper {
    display: flex;
    flex: 0.5;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo {
    height: 50px;
    width: auto;
}

.prompt {
    font-size: 34px;
    margin: 40px 0;
}

.form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 350px;
}

.input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 12px 0;
}

.label-addon {
    position: absolute;
    top: 0px;
    right: 3px;
    text-decoration: underline;
    color: #6D06C6;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
}

input {
    font-size: 16px;
    padding: 11px 16px 11px 16px;
    line-height: 21px;
    outline: none;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    font-family: Roboto;
}

input::placeholder {
    color: #000;
    opacity: 0.4;
}

label {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 7px;
}

button {
    cursor: pointer;
    margin-top: 20px;
    background: #6C08C5;
    color: #fff;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
    min-height: 50px;
}

.outline-button {
    color: #6C08C5;
    border: 1px solid #6C08C5;
    background: #fff;
}

.disabled-button {
    background: #E8E8E8;
}

.image-block {
    display: flex;
    flex: 0.5;
    height: 100%;
    position: relative;
}

.image-block-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    width: 500px;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
}

.error-message {
    color: #D0021B;
    font-size: 13px;
    margin-top: 2px;
}

.error {
    border-color: #D0021B;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 990;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    z-index: 991;
    background: #fff;
    padding: 25px 40px 35px 40px;
    box-shadow: 0 0 20px 6px rgba(0,0,0, 0.5);
    border-radius: 20px;
    position: relative;
    width: 480px;
}

.modal-header {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 17px;
    line-height: 36px;
}

.modal-close-icon-wrapper {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
}

.modal-buttons-containers {
    display: flex;
    justify-content: space-between;
    min-width: 300px;
}

.modal-inputs-wrapper {
    margin: 23px 0 20px 0;
}

.buttons-container-divider {
    padding: 5px;
}

.modal-image {
    margin-top: 15px;
    margin-bottom: 20px;
}

.modal-text-button-divider {
    margin-top: 20px;
}

.eye-wrapper {
    position: absolute;
    top: 33px;
    right: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 1075px) {
    .image-block { display: none; }
    .login-block-wrapper {flex: 1}
}